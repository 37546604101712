import { Tooltip } from "@material-ui/core";
import React, { useState, useMemo } from "react";
import CityDialog from "./CityDialog";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

// URL to a map of US states
const geoUrl =
  "https://raw.githubusercontent.com/markmarkoh/datamaps/master/src/js/data/usa.topo.json";

const cityToCoords = {
  "kennewick": [-119.1372, 46.2087],
  "seattle": [-122.3321, 47.6062],
  "mount vernon": [-122.3344, 48.4221],
  "oak harbor": [-122.6566, 48.2932],
  "oak grove": [-122.5787, 45.4159],
  "bremerton": [-122.6219, 47.5673],
  "tulalip": [-122.2923, 48.0800],
  "redmond": [-122.1215, 47.6740],
  "port townsend": [-122.7554, 48.1170],
  "snohomish": [-122.0930, 47.9129],
  "spokane": [-117.4260, 47.6588],
  "bellingham": [-122.4788, 48.7519],
  "olympia": [-122.9007, 47.0379],
  "vancouver": [-122.6673, 45.6387],
  "lynnwood": [-122.2834, 47.8209],
  "federal way": [-122.3126, 47.3223],
  "redwood city": [-122.2364, 37.4852],
  "mt vernon": [-122.3344, 48.4221],
  "port angeles": [-123.4307, 48.1181],
  "san francisco": [-122.4194, 37.7749],
  "salem": [-123.0351, 44.9429],
  "the dalles": [-121.1802, 45.5946],
  "ocean shores": [-124.1588, 47.2243],
  "gig harbor": [-122.5801, 47.3293],
  "issaquah": [-122.0326, 47.5301],
  "tacoma": [-122.4443, 47.2529],
  "las vegas": [-115.1398, 36.1699],
  "seaside": [-123.9226, 45.9932],
  "yakima": [-120.5059, 46.6021],
  "woodinville": [-122.1648, 47.7543],
  "wenatchee": [-120.3103, 47.4235],
  "carmichael": [-121.3318, 38.6171],
  "denver": [-104.9903, 39.7392],
  "kent": [-122.2348, 47.3809],
  "bainbridge": [-122.5346, 47.6346],
  "aberdeen": [-123.8157, 46.9754],
  "puyallup": [-122.2930, 47.1854],
  "cle elum": [-120.9637, 47.1955],
  "anchorage": [-149.9003, 61.2181],
  "camano island": [-122.5404, 48.1717],
  "sequim": [-123.1018, 48.0794],
  "spokane valley": [-117.2399, 47.6732],
  "coeur d'alene": [-116.7805, 47.6777],
  "salt lake city": [-111.8910, 40.7608],
  "scottsdale": [-111.9260, 33.4942],
  "chino": [-117.6889, 34.0122],
  "fairbanks": [-147.7164, 64.8378],
  "costa mesa": [-117.9187, 33.6412],
  "granite city": [-90.1238, 38.7014],
  "cedar park": [-97.8203, 30.5052],
  "washington": [-77.0369, 38.9072],
  "monmouth": [-123.2108, 44.8487],
  "crestwood": [-87.7359, 41.6611],
  "cincinnati": [-84.5120, 39.1031],
  "evanston": [-87.6901, 42.0451],
  "boise": [-116.2023, 43.6150],
  "south salt lake": [-111.8904, 40.7188],
  "berkeley": [-122.2727, 37.8715],
  "walla walla": [-118.3430, 46.0646],
  "missoula": [-113.9966, 46.8721],
  "houston": [-95.3698, 29.7604],
  "eugene": [-123.0868, 44.0521],
  "south san francisco": [-122.4054, 37.6547],
  "chicago": [-87.6298, 41.8781],
  "portland": [-122.6587, 45.5051],
  "san diego": [-117.1611, 32.7157],
  "avondale estates": [-84.2640, 33.7715],
  "richland": [-119.2845, 46.2857],
  "west covina": [-117.9389, 34.0686],
  "playa del rey": [-118.4476, 33.9564],
  "los angeles": [-118.2437, 34.0522],
  "fort worth": [-97.3308, 32.7555],
  "bensenville": [-87.9401, 41.9550],
  "henderson": [-115.1398, 36.0395],
  "sherman oaks": [-118.4484, 34.1489],
  "kelso": [-122.9050, 46.1468],
  "stafford": [-95.5636, 29.6156],
  "west roxbury": [-71.1627, 42.2793],
  "raleigh": [-78.6382, 35.7796],
  "milwaukee": [-87.9065, 43.0389],
  "tucson": [-110.9265, 32.2226],
  "philadelphia": [-75.1652, 39.9526],
  "omaha": [-95.9345, 41.2565],
  "lincoln": [-96.6852, 40.8136],
  "dallas": [-96.7969, 32.7767],
  "sunnyside": [-120.0087, 46.3237],
  "belmont": [-122.304199, 37.524174],
  "new albany": [-85.8241, 38.2856],
  "redlands": [-117.1916, 34.0556],
  "dayton": [-84.1916, 39.7589],
  "corvallis": [-123.2620, 44.5646],
  "st. louis": [-90.1994, 38.6270],
  "rochester": [-92.4630, 44.0121],
  "san jose": [-121.8863, 37.3382],
  "provo": [-111.6585, 40.2338],
  "fridley": [-93.2636, 45.0847],
  "anacortes": [-122.6190, 48.5126],
  "cathlamet": [-123.3806, 46.2054],
  "bainbridge island": [-122.5356, 47.6410],
  "greenwood village": [-104.9224, 39.6172],
  "colorado springs": [-104.8213, 38.8339],
  "ellensburg": [-120.5479, 46.9965],
  "moscow": [-117.0002, 46.7323],
  "springfield": [-89.6437, 39.7817],
  "gilbert": [-111.7890, 33.3528],
  "cleveland": [-81.6944, 41.4993],
  "arlington": [-97.1081, 32.7357],
  "burlington": [-122.3384, 48.4758],
  "lake stevens": [-122.0944, 48.0151],
  "akron": [-81.5190, 41.0814],
  "pullman": [-117.1734, 46.7319],
  "santa fe": [-105.9378, 35.6869],
  "albuquerque": [-106.6504, 35.0844],
  "tucker": [-84.2171, 33.8531],
  "nashville": [-86.7816, 36.1627],
  "las cruces": [-106.6504, 32.3199],
  "east haven": [-72.8684, 41.2848],
  "austin": [-97.7500, 30.2500],
  "lacey": [-122.8230, 47.0343],
  "anaheim": [-117.9143, 33.8366],
  "dc": [-77.0364, 38.8951],
  "san antonio": [-98.4936, 29.4241],
  "bend": [-121.3153, 44.0682],
  "tumwater": [-122.9020, 47.0176],
  "sacramento": [-121.4944, 38.5816],
  "fort collins": [-105.0844, 40.5853],
  "napa": [-122.2869, 38.2975],
  "whidbey island": [-122.5855556, 48.0963889],
  "orlando": [-81.3790304, 28.5421109],
  "tampa": [-82.458444, 27.9477595],
  "miami": [-80.1936589, 25.7742658],
  "boise": [-116.200886, 43.6166163],
  "lexington": [-84.4970392, 38.046407],
  "louisville": [-85.759407, 38.2542376],
  "charlotte": [-80.8431267, 35.2270869],
  "atlanta": [-84.3901849, 33.7490987],
  "phoenix": [-112.0741417, 33.4484367],
  "flagstaff": [-111.651272, 35.198284],
  "minneapolis": [-93.2650108, 44.977753],
  "indianapolis": [-86.158068, 39.768403],
  "fort wayne": [-85.1393513, 41.079273],
  "detroit": [-83.0457538, 42.331427],
  "des moines": [-93.6091064, 41.5868353],
  "kansas city": [-94.5785667, 39.0997265],
  "columbus": [-82.9987942, 39.9611755]
};

function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

// Seattle's coordinates
const markers = [
  {
    markerOffset: -30,
    name: "Seattle",
    events: [],
    coordinates: [-122.3321, 47.6062],
  },
];

const handleClick = (name) => {
  alert(`You clicked on ${name}`);
};

const MapChart = ({ events }) => {
  const [open, setOpen] = useState(false);
  const [citySelected, setCitySelected] = useState("");
  const [eventsSelected, setEventsSelected] = useState([]);
  const markers = useMemo(() => {
    if (!events.length) return [];

    let result = [];
    const cityDict = {};
    events.forEach((event) => {
      const city = (event.city || "").trim().toLowerCase();
      if (!city) return null;
      let arr = cityDict[city] || [];
      arr.push(event);
      cityDict[city] = arr;
    });
    Object.keys(cityDict).forEach((city) => {
      const coordinates = cityToCoords[city];
      if (!coordinates) return;
      const eventList = cityDict[city];
      result.push({
        name: capitalizeWords(city),
        events: eventList,
        coordinates,
      });
    });
    return result;
  }, [events.length]);

  return (
    <>
      <ComposableMap projection="geoAlbersUsa" className="events-map">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#89a4bc"
                stroke="#FFF"
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates, events }) => {
          return (
            <Marker key={name} coordinates={coordinates}>
              <Tooltip
                title={
                  name +
                  ": " +
                  events.length.toString() +
                  " events. Click for details"
                }
              >
                <circle
                  r={10}
                  fill="#d4454f"
                  stroke="#fff"
                  strokeWidth={2}
                  className="u-cursorPointer"
                  onClick={() => { setCitySelected(name); setEventsSelected(events); setOpen(true)}}
                />
              </Tooltip>

              {/*<text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          >
            {name}
          </text>*/}
            </Marker>
          );
        })}
      </ComposableMap>
      <CityDialog events={eventsSelected} city={citySelected} onClose={() => { setOpen(false); setCitySelected("");  setEventsSelected([])}} open={open} />
    </>
  );
};

export default MapChart;
